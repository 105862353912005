import React, { Component } from "react";
import {
  Box,
  Button,
  Typography,
  createTheme,
  ThemeProvider,
  Grid,
  Card,
  CardContent,
  Divider,
  AppBar,
  Toolbar,
  Menu,
  IconButton,
  Modal,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import "./Landing.css";
import logo from "./logo.jpeg";
import * as emailjs from "emailjs-com";
import Background from "./background.jpeg";
import service1 from "./service-1.jpeg";
import service2 from "./service-2.jpeg";
import service3 from "./service-3.jpeg";
import service4 from "./service-4.jpeg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Fade from "react-reveal/Fade";
export class Landing extends Component {
  constructor(props) {
    super(props);
    this.aboutRef = React.createRef();
    this.contactRef = React.createRef();
    this.servicesRef = React.createRef();
    this.state = {
      menu: null,
      signUpModal: false,
      english: true,
      appbarColor: "transparent",
      name: "",
      email: "",
      message: "",
      open: false,
    };
  }

  render() {
    const changeColor = () => {
      if (window.scrollY >= 600) {
        this.setState({ appbarColor: "#333333" });
      } else {
        this.setState({ appbarColor: "transparent" });
      }
    };
    window.addEventListener("scroll", changeColor);
    console.log(window.scrollY);
    const theme = createTheme({
      typography: {
        fontFamily: "Poppins",
        button: {
          textTransform: "none",
        },
      },
    });
    const pages = ["Products", "Pricing", "Blog"];
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };
    const idioma = this.state.english;
    const handleChange = (e) => {
      this.setState({
        [e.target.id]: e.target.value,
      });
    };
    const handleClick = () => {
      this.setState({ open: true });
      resetForm();
    };
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      this.setState({ open: false });
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const { name, email, message } = this.state;
      const templateParams = {
        from_name: name,
        from_email: email,
        to_name: "/*YOUR NAME OR COMPANY*/",
        message_html: message,
      };
      emailjs.send(
        "service_11ik3uo",
        "template_1zqf8x8",
        templateParams,
        "user_f8Yyr8KJpwgZcPzonS0xk"
      );
      handleClick();
    };

    const resetForm = () => {
      this.setState({
        name: "",
        email: "",
        message: "",
      });
    };

    return (
      <div id="scroll">
        <ThemeProvider theme={theme}>
          <div>
            <Box>
              <AppBar
                position="fixed"
                elevation={0}
                style={{
                  background: "transparent",
                  boxShadow: "none",
                  background: this.state.appbarColor,
                }}
              >
                <Toolbar>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1, fontSize: "25px", marginLeft: "3%" }}
                  ></Typography>

                  <Box
                    sx={{
                      display: { xs: "none", md: "flex" },
                      marginRight: "3%",
                    }}
                  >
                    <Button
                      color="inherit"
                      size="large"
                      style={{ fontSize: "15px" }}
                      onClick={() => this.executeScrollAbout()}
                    >
                      {idioma ? "About" : "Acerca de"}
                    </Button>
                    <Button
                      color="inherit"
                      size="large"
                      style={{ fontSize: "15px" }}
                      onClick={() => this.executeScrollServices()}
                    >
                      {idioma ? "Services" : "Servicios"}
                    </Button>
                    <Button
                      color="inherit"
                      size="large"
                      style={{ fontSize: "15px" }}
                      onClick={() => this.executeScrollContact()}
                    >
                      {idioma ? "Contact" : "Contacto"}
                    </Button>
                    <Button
                      color="inherit"
                      variant="outlined"
                      size="large"
                      style={{ fontSize: "15px", marginLeft: "10px" }}
                      onClick={() =>
                        this.setState({ english: !this.state.english })
                      }
                    >
                      {idioma ? "VERSION EN ESPAÑOL" : "ENGLISH VERSION"}
                    </Button>
                  </Box>
                  <Box sx={{ display: { xs: "flex", md: "none" } }}>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => this.setState({ menu: true })}
                      color="inherit"
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      // anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      anchorEl={this.state.menu}
                      open={Boolean(this.state.menu)}
                      disableScrollLock={true}
                      onClose={() => this.setState({ menu: null })}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      // open={Boolean(anchorElNav)}
                      // onClose={handleCloseNavMenu}
                      sx={{
                        display: { xs: "block", md: "none" },
                      }}
                    >
                      <MenuItem onClick={() => this.executeScrollAbout()}>
                        <Typography textAlign="center">
                          {idioma ? "About" : "Acerca de"}
                        </Typography>
                      </MenuItem>
                      <MenuItem onClick={() => this.executeScrollServices()}>
                        <Typography textAlign="center">
                          {idioma ? "Services" : "Servicios"}
                        </Typography>
                      </MenuItem>
                      <MenuItem onClick={() => this.executeScrollContact()}>
                        <Typography textAlign="center">
                          {idioma ? "Contact" : "Contacto"}
                        </Typography>
                      </MenuItem>
                      <Button
                        color="inherit"
                        variant="outlined"
                        size="large"
                        style={{ fontSize: "15px", marginLeft: "10px" }}
                        onClick={() =>
                          this.setState({ english: !this.state.english })
                        }
                      >
                        {idioma ? "VERSION EN ESPAÑOL" : "ENGLISH VERSION"}
                      </Button>
                    </Menu>
                  </Box>
                </Toolbar>
              </AppBar>
            </Box>
            <div
              style={{
                backgroundImage: `url(${Background})`,
                backgroundSize: "cover",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Fade top>
                  <Typography
                    style={{
                      color: "#cb9e34",
                      fontWeight: "800",
                      textAlign: "center",
                      marginBottom: "5px",
                    }}
                    sx={{
                      typography: {
                        xs: { fontSize: "35px" },
                        md: { fontSize: "40px" },
                        lg: { fontSize: "60px" },
                      },
                    }}
                  >
                    OG FINANCIAL SERVICES LLC
                  </Typography>
                </Fade>
                <Fade top>
                  <Typography
                    style={{
                      color: "#cb9e34",
                      textAlign: "center",
                      marginBottom: "30px",
                    }}
                    sx={{
                      typography: {
                        xs: { fontSize: "25px" },
                        md: { fontSize: "30px" },
                        lg: { fontSize: "30px" },
                      },
                    }}
                  >
                    {idioma
                      ? "TAX, ACCOUNTING & PAYROLL"
                      : "IMPUESTOS, CONTABILIDAD Y NÓMINAS"}
                  </Typography>
                </Fade>
                <Fade bottom>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      padding: "15px",
                      fontSize: "15px",
                      color: "white",
                      backgroundColor: "#13366a",
                    }}
                    onClick={() => this.executeScrollAbout()}
                  >
                    {idioma ? "MORE INFO" : "MAS INFORMACIÓN"}
                  </Button>
                </Fade>
              </Box>
            </div>
            <div style={{ backgroundColor: "#f6f6f6" }} ref={this.aboutRef}>
              <Box p={10}>
                <Snackbar
                  open={this.state.open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    {idioma
                      ? "Your message was sent!"
                      : "Su mensaje ha sido enviado!"}
                  </Alert>
                </Snackbar>
                <Grid container spacing={6}>
                  <Grid item lg={1}></Grid>
                  <Grid item lg={6} sx={12}>
                    <Fade bottom>
                      <Typography
                        style={{
                          color: "#cb9e34",
                          fontWeight: "600",
                          textAlign: "left",
                        }}
                        sx={{
                          typography: {
                            xs: { fontSize: "20px" },
                            md: { fontSize: "20px" },
                            lg: { fontSize: "25px" },
                          },
                        }}
                      >
                        {idioma ? (
                          <div>
                            Welcome to <b>OG FINANCIAL SERVICES</b>
                          </div>
                        ) : (
                          <div>
                            Bienvenido a <b>OG FINANCIAL SERVICES</b>
                          </div>
                        )}
                      </Typography>
                    </Fade>
                    <Box p={1} />
                    <Fade bottom>
                      <Typography
                        style={{ color: "black", textAlign: "left" }}
                        sx={{
                          typography: {
                            xs: { fontSize: "15px" },
                            md: { fontSize: "15px" },
                            lg: { fontSize: "15px" },
                          },
                        }}
                      >
                        {idioma
                          ? "We´re a firm of specialists in business planning."
                          : "Somos una firma de especialistas en planeación de negocios."}
                        <br />
                        <Box p={1} />
                        {idioma
                          ? "We offer our clients advice and solutions that allow them to optimize their duties on a Federal, State and Local level, all thanks to the wide experience we have according to your business activities."
                          : "Ofrecemos a nuestros clientes asesoría y soluciones que les permitan optimizar sus recursos y tener la tranquilidad de estar cumpliendo con las obligaciones Federales, Estatales y locales, gracias a la amplia experiencia que tenemos de acuerdo a la actividad de su empresa."}
                        <br />
                        <Box p={1} />
                        {idioma
                          ? " Our services let your company take control over costs and expenses, have access to new techcnologies and identify new sources of income."
                          : "Nuestros servicios le permiten a su empresa controlar los costos y gastos, tener acceso a nuevas tecnologías, e identificar nuevas fuentes de ingresos."}
                      </Typography>
                    </Fade>
                    <Box p={2} />
                    <Fade bottom>
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          padding: "15px",
                          fontSize: "15px",
                          color: "white",
                          backgroundColor: "#13366a",
                        }}
                        onClick={() => this.executeScrollServices()}
                      >
                        {idioma ? "MORE INFO" : "VER TODOS NUESTROS SERVICIOS"}
                      </Button>
                    </Fade>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    sx={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: { xs: "none", md: "flex" },
                      }}
                    >
                      <img src={logo}></img>
                    </Box>
                  </Grid>
                  <Grid item lg={1}></Grid>
                </Grid>
              </Box>
            </div>
            <div style={{ backgroundColor: "#13366a" }} ref={this.servicesRef}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginRight: "10%",
                  marginLeft: "10%",
                }}
              >
                <Fade top>
                  <Typography
                    style={{
                      color: "white",
                      fontWeight: "800",
                      textAlign: "left",
                      marginBottom: "40px",
                      marginTop: "40px",
                    }}
                    sx={{
                      typography: {
                        xs: { fontSize: "25px" },
                        md: { fontSize: "30px" },
                        lg: { fontSize: "40px" },
                      },
                    }}
                  >
                    {idioma ? "Our Services" : "Servicios"}
                  </Typography>
                </Fade>
                <Grid container spacing={4}>
                  <Grid item lg={3} sx={12}>
                    <Fade bottom>
                      <Card
                        style={{
                          backgroundColor: "#13366a",
                          padding: "20px",
                          borderRadius: "20px",
                          elevation: "0",
                          boxShadow: "0px 0px",
                        }}
                      >
                        <CardContent
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Card
                            style={{
                              backgroundColor: "#cfb8ff",
                              width: "200px",
                              height: "200px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "200px",
                              border: "14px solid #2c4b79",
                            }}
                          >
                            <img src={service1} width={200} height={200} />
                          </Card>
                          <Typography
                            style={{
                              color: "#cb9e34",
                              fontWeight: "600",
                              textAlign: "center",
                              margin: "15px",
                            }}
                            sx={{
                              typography: {
                                xs: { fontSize: "20px" },
                                md: { fontSize: "20px" },
                                lg: { fontSize: "25px" },
                              },
                            }}
                          >
                            {idioma ? "Accounting" : "Contabilidad"}
                          </Typography>
                          <Typography
                            style={{ color: "white", textAlign: "center" }}
                            sx={{
                              typography: {
                                xs: { fontSize: "15px" },
                                md: { fontSize: "15px" },
                                lg: { fontSize: "15px" },
                              },
                            }}
                          >
                            {idioma
                              ? "Vendor bill processing "
                              : "Procesamiento de Facturas"}
                            <br />
                            <Box p={1} />
                            {idioma
                              ? "Financial statement preparation"
                              : "Preparación de estados financieros"}{" "}
                            <br />
                            <Box p={1} />
                            {idioma
                              ? "General ledger transaction recording, processing and reconciliation"
                              : "Registro, procesamiento y reconciliación"}
                            <br />
                            <Box p={1} />
                            {idioma
                              ? "Fixed asset and depreciation schedule maintenance"
                              : "Mantenimiento de activos fijos y programa de depreciación"}
                            <br />
                            <Box p={1} />
                            {idioma
                              ? "Bank and credit card account monthly reconciliation"
                              : "Conciliación mensual de cuentas bancarias y de tarjetas de crédito"}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Fade>
                  </Grid>
                  <Grid item lg={3} sm={12}>
                    <Fade bottom>
                      <Card
                        style={{
                          backgroundColor: "#13366a",
                          padding: "20px",
                          borderRadius: "20px",
                          elevation: "0",
                          boxShadow: "0px 0px",
                        }}
                      >
                        <CardContent
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Card
                            style={{
                              backgroundColor: "#cfb8ff",
                              width: "200px",
                              height: "200px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "200px",
                              border: "14px solid #2c4b79",
                            }}
                          >
                            <img src={service2} width={200} height={200} />
                          </Card>
                          <Typography
                            style={{
                              color: "#cb9e34",
                              fontWeight: "600",
                              textAlign: "center",
                              margin: "15px",
                            }}
                            sx={{
                              typography: {
                                xs: { fontSize: "20px" },
                                md: { fontSize: "20px" },
                                lg: { fontSize: "25px" },
                              },
                            }}
                          >
                            {idioma ? "Payroll" : "Nómina de sueldos"}
                          </Typography>
                          <Typography
                            style={{ color: "white", textAlign: "center" }}
                            sx={{
                              typography: {
                                xs: { fontSize: "15px" },
                                md: { fontSize: "15px" },
                                lg: { fontSize: "15px" },
                              },
                            }}
                          >
                            {idioma
                              ? "Payroll processing"
                              : "Procesamiento de nómina"}
                            <br />
                            <Box p={1} />
                            {idioma
                              ? "Payroll tax compliance and planning"
                              : "Cumplimiento y planificación de impuestos sobre la nómina"}{" "}
                            <br />
                            <Box p={1} />
                            {idioma
                              ? "Federal, state and local payroll tax preparation and filling"
                              : "Preparación y presentación de impuestos sobre la nómina federales, estatales y locales"}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Fade>
                  </Grid>
                  <Grid item lg={3} sm={12}>
                    <Fade bottom>
                      <Card
                        style={{
                          backgroundColor: "#13366a",
                          padding: "20px",
                          borderRadius: "20px",
                          elevation: "0",
                          boxShadow: "0px 0px",
                        }}
                      >
                        <CardContent
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Card
                            style={{
                              backgroundColor: "#cfb8ff",
                              width: "200px",
                              height: "200px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "200px",
                              border: "14px solid #2c4b79",
                            }}
                          >
                            <img src={service3} width={200} height={200} />
                          </Card>
                          <Typography
                            style={{
                              color: "#cb9e34",
                              fontWeight: "600",
                              textAlign: "center",
                              margin: "15px",
                            }}
                            sx={{
                              typography: {
                                xs: { fontSize: "20px" },
                                md: { fontSize: "20px" },
                                lg: { fontSize: "25px" },
                              },
                            }}
                          >
                            {idioma ? "Taxes" : "Impuestos"}
                          </Typography>
                          <Typography
                            style={{ color: "white", textAlign: "center" }}
                            sx={{
                              typography: {
                                xs: { fontSize: "15px" },
                                md: { fontSize: "15px" },
                                lg: { fontSize: "15px" },
                              },
                            }}
                          >
                            {idioma
                              ? "W2 and 1099 preparation and filling"
                              : "Preparación y llenado de W2 y 1099"}{" "}
                            <br />
                            <Box p={1} />
                            {idioma
                              ? "Federal, state and local tax preparation and filling"
                              : "Preparación y presentación de impuestos federales, estatales y locales"}
                            <br />
                          </Typography>
                        </CardContent>
                      </Card>
                    </Fade>
                  </Grid>
                  <Grid item lg={3} sm={12}>
                    <Fade bottom>
                      <Card
                        style={{
                          backgroundColor: "#13366a",
                          padding: "20px",
                          borderRadius: "20px",
                          elevation: "0",
                          boxShadow: "0px 0px",
                        }}
                      >
                        <CardContent
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Card
                            style={{
                              backgroundColor: "#cfb8ff",
                              width: "200px",
                              height: "200px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "200px",
                              border: "14px solid #2c4b79",
                            }}
                          >
                            <img src={service4} width={200} height={200} />
                          </Card>
                          <Typography
                            style={{
                              color: "#cb9e34",
                              fontWeight: "600",
                              textAlign: "center",
                              margin: "15px",
                            }}
                            sx={{
                              typography: {
                                xs: { fontSize: "20px" },
                                md: { fontSize: "20px" },
                                lg: { fontSize: "25px" },
                              },
                            }}
                          >
                            {idioma ? "Advisory" : "Asesoramiento"}
                          </Typography>
                          <Typography
                            style={{ color: "white", textAlign: "center" }}
                            sx={{
                              typography: {
                                xs: { fontSize: "15px" },
                                md: { fontSize: "15px" },
                                lg: { fontSize: "15px" },
                              },
                            }}
                          >
                            {idioma
                              ? "Business formation and entity selection"
                              : "Constitución de empresas y selección de entidades"}{" "}
                            <br />
                            <Box p={1} />
                            {idioma
                              ? "Business purchase and sale advisory services"
                              : "Servicios de asesoramiento en compra y venta de empresas"}{" "}
                            <br />
                            <Box p={1} />
                            {idioma
                              ? "Planning, budgeting, and forecasting services"
                              : "Servicios de planificación, elaboración de presupuestos y pronósticos"}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Fade>
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div style={{ backgroundColor: "#f6f6f6" }} ref={this.contactRef}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Fade top>
                  <Typography
                    style={{
                      color: "#cb9e34",
                      fontWeight: "800",
                      textAlign: "left",
                      marginBottom: "40px",
                      marginTop: "40px",
                    }}
                    sx={{
                      typography: {
                        xs: { fontSize: "25px" },
                        md: { fontSize: "30px" },
                        lg: { fontSize: "40px" },
                      },
                    }}
                  >
                    {idioma ? "Contact Us" : "Contactanos"}
                  </Typography>
                </Fade>
                <Grid
                  container
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid item lg={4} sx={12} md={4}>
                    <Fade bottom>
                      <Typography
                        style={{
                          color: "#cb9e34",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                        sx={{
                          typography: {
                            xs: { fontSize: "15px" },
                            md: { fontSize: "20px" },
                            lg: { fontSize: "20px" },
                          },
                        }}
                      >
                        {idioma ? "ADDRESS" : "DIRECCION"}
                      </Typography>
                    </Fade>
                    <Fade left>
                      <Divider
                        sx={{
                          marginLeft: "40%",
                          marginRight: "40%",
                          borderBottomWidth: 4,
                          marginTop: "5px",
                          marginBottom: "10px",
                        }}
                      />
                    </Fade>
                    <Box p={1} />
                    <Fade bottom>
                      <Typography
                        style={{ color: "black", textAlign: "center" }}
                        sx={{
                          typography: {
                            xs: { fontSize: "15px" },
                            md: { fontSize: "15px" },
                            lg: { fontSize: "15px" },
                          },
                        }}
                      >
                        1211 Minnesota Ave <br />
                        <Box p={1} />
                        Kansas City, KS 66102
                      </Typography>
                    </Fade>
                  </Grid>
                  <Grid item lg={4} sx={12} md={4}>
                    <Fade bottom>
                      <Typography
                        style={{
                          color: "#cb9e34",
                          fontWeight: "400",
                          textAlign: "center",
                          marginTop: "20px",
                        }}
                        sx={{
                          typography: {
                            xs: { fontSize: "15px" },
                            md: { fontSize: "20px" },
                            lg: { fontSize: "20px" },
                          },
                        }}
                      >
                        {idioma ? "WORKING HOURS" : "HORAS DE TRABAJO"}
                      </Typography>
                    </Fade>
                    <Fade center>
                      <Divider
                        sx={{
                          marginLeft: "40%",
                          marginRight: "40%",
                          borderBottomWidth: 4,
                          marginTop: "5px",
                          marginBottom: "10px",
                        }}
                      />
                    </Fade>
                    <Box p={1} />
                    <Fade bottom>
                      <Typography
                        style={{ color: "black", textAlign: "center" }}
                        sx={{
                          typography: {
                            xs: { fontSize: "15px" },
                            md: { fontSize: "15px" },
                            lg: { fontSize: "15px" },
                          },
                        }}
                      >
                        {idioma
                          ? "Monday-Friday: 09:00 - 17:00"
                          : "Lunes-Viernes: 09:00 - 17:00"}{" "}
                        <br />
                        <Box p={1} />
                        {idioma
                          ? "Saturday: 10:00 - 14:00"
                          : "Sabado: 10:00 - 14:00"}{" "}
                        <br />
                        <Box p={1} />
                        {idioma ? "Sunday: CLOSED" : "Domingo: CLOSED"}
                      </Typography>
                    </Fade>
                  </Grid>
                  <Grid item lg={4} sx={12} md={4}>
                    <Fade bottom>
                      <Typography
                        style={{
                          color: "#cb9e34",
                          fontWeight: "400",
                          textAlign: "center",
                          marginTop: "20px",
                        }}
                        sx={{
                          typography: {
                            xs: { fontSize: "15px" },
                            md: { fontSize: "20px" },
                            lg: { fontSize: "20px" },
                          },
                        }}
                      >
                        {idioma ? "CONTACT INFO" : "INFORMACIÓN DE CONTACTO"}
                      </Typography>
                    </Fade>
                    <Fade right>
                      <Divider
                        sx={{
                          marginLeft: "40%",
                          marginRight: "40%",
                          borderBottomWidth: 4,
                          marginTop: "5px",
                          marginBottom: "10px",
                        }}
                      />
                    </Fade>
                    <Box p={1} />
                    <Fade bottom>
                      <Typography
                        style={{ color: "black", textAlign: "center" }}
                        sx={{
                          typography: {
                            xs: { fontSize: "15px" },
                            md: { fontSize: "15px" },
                            lg: { fontSize: "15px" },
                          },
                        }}
                      >
                        {idioma
                          ? "Phone: (913) 296 3025"
                          : "Telefono: (913) 296 3025"}{" "}
                        <br />
                        <Box p={1} />
                        Email: ogfinancials@gmail.com
                      </Typography>
                    </Fade>
                  </Grid>
                </Grid>
                <Box p={4} />
                <Fade bottom>
                  <Typography
                    style={{
                      color: "#cb9e34",
                      fontWeight: "400",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                    sx={{
                      typography: {
                        xs: { fontSize: "15px" },
                        md: { fontSize: "20px" },
                        lg: { fontSize: "20px" },
                      },
                    }}
                  >
                    {idioma ? "LEAVE US A MESSAGE" : "DEJANOS UN MENSAJE"}
                  </Typography>
                </Fade>
                <Box p={2} />
                <Box sx={{ marginLeft: "10%", marginRight: "10%" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        placeholder="Name"
                        multiline
                        fullWidth
                        onChange={handleChange}
                        value={this.state.name}
                        id="name"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        placeholder="Email"
                        multiline
                        fullWidth
                        onChange={handleChange}
                        value={this.state.email}
                        id="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        placeholder="Message"
                        onChange={handleChange}
                        value={this.state.message}
                        id="message"
                        multiline
                        rows={2}
                        maxRows={4}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Box p={1} />
                </Box>
                <Fade bottom>
                  <form onSubmit={handleSubmit}>
                    {/* <Button
                      variant="contained"
                      size="small"
                      style={{
                        padding: "15px",
                        fontSize: "15px",
                        color: "white",
                        backgroundColor: "#13366a",
                        textAlign: "center",
                      }}
                    >
                      {idioma ? "SEND MESSAGE" : "ENVIAR MENSAJE"}
                    </Button> */}
                    <button
                      style={{
                        padding: "15px",
                        fontSize: "15px",
                        color: "white",
                        backgroundColor: "#13366a",
                        textAlign: "center",
                      }}
                    >
                      Send
                    </button>
                  </form>

                  <Box p={4} />
                </Fade>
              </Box>
            </div>
          </div>
        </ThemeProvider>
      </div>
    );
  }
  executeScrollAbout = () => this.aboutRef.current.scrollIntoView();
  executeScrollContact = () => this.contactRef.current.scrollIntoView();
  executeScrollServices = () => this.servicesRef.current.scrollIntoView();
}

export default Landing;
